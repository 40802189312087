<script>
import "./assets/sass/main.scss";
import Navbar from "./components/Navbar.vue";
import Herobanner from "./components/Herobanner.vue";
import Opdrachtgevers from "./components/Opdrachtgevers.vue";
import Studenten from "./components/Studenten.vue";
import Docenten from "./components/Docenten.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Herobanner,
    Opdrachtgevers,
    Studenten,
    Docenten,
    Footer
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <div class="container">
    <Navbar></Navbar>
    <Herobanner></Herobanner>
    <Opdrachtgevers></Opdrachtgevers>
    <Studenten></Studenten>
    <Docenten></Docenten>
    <Footer></Footer>
  </div>
</template>