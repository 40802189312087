<script>
export default {
  name: "Herobanner",
};
</script>

<template>
  <section class="hero is-large p-3">
    <div class="hero-body">
      <div class="columns is-desktop">
        <div class="column is-5-desktop is-offset-1-desktop">
          <h1 class="">Tijd om werk te maken van je ideeën</h1>
          <p>
            Het Dutch Software Lab is er voor jou. Voor de ondernemer, leraar,
            schooldirecteur of gemeente met goede ideeën en een beperkt budget.
            Voor de ICT-docent die weer zelf wil ervaren hoe software bouwen
            voelt. Voor de student die een inspirerende bijbaan, stage of
            tussenbaan zoekt. Wij bouwen software in een maatschappelijke
            context. We doen het professioneel, we doen het innovatief, we doen
            het samen.
          </p>
          <br />
          <p>
            Het Dutch Software Lab is een initiatief van de Haagse Hogeschool. 
            We werken samen met de Dutch Innovation Community.
          </p>
        </div>
        <div class="column is-half-desktop">
          <img src="../assets/scrum.jpg" alt="" class="mr-0" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.hero {
  background-color: #fff;
}
.hero-text {
  margin-top: 40px;
}
</style>
