<script>
export default {
  name: "Navbar",
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
  },
};
</script>

<template>
  <nav class="navbar is-white" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="#">
          <img
            src="../assets/dsl-logo.png"
            width="160"
            class="dsl-logo"
            alt="logo"
          />
        </a>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          :class="{ 'is-active': isMobileMenuOpen }"
          @click="toggleMobileMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbar"
        class="navbar-menu"
        :class="{ 'is-active': isMobileMenuOpen }"
      >
        <a class="navbar-item has-text-weight-bold" href="#opdrachtgevers">
          Opdrachtgevers
        </a>
        <a class="navbar-item has-text-weight-bold" href="#studenten">
          Studenten
        </a>
        <a class="navbar-item has-text-weight-bold" href="#docenten">
          Docenten
        </a>
        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <a
                href="mailto:info@dutchsoftwarelab.nl"
                class="button is-primary is-size-5 p-4"
              >
                <strong>Start een project met ons</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<style scoped>
.navbar {
  padding: 30px 80px;
}

.navbar-menu .navbar-item {
  font-size: 14pt;
  color: #000;
}

.dsl-logo {
  margin-right: 20px;
}
</style>
