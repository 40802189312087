<script>
export default {
  name: "Opdrachtgevers",
};
</script>

<template>
  <section
    id="opdrachtgevers"
    class="section is-small pl-0 has-background-white"
  >
    <div class="columns is-mobile">
      <div class="column is-11-mobile is-11-desktop">
        <div class="has-background-info">
          <div class="columns is-desktop">
            <div class="column is-7-desktop p-6">
              <h4>01</h4>
              <h2 class="has-text-white is-size-3 mt-4 mb-4">
                Aan alle potentiële opdrachtgevers:
              </h2>
              <p class="has-text-white">
                Een leraar lichamelijke opvoeding laat zijn leerlingen
                reflecteren op hun eigen prestaties. Hij doet dat op een leuke
                manier en zoekt een idee om zijn vondst te digitaliseren. Een
                mkb-directeur laat een digitaal platform ontwikkelen waarop hij
                z’n klanten kan verzamelen en hun customer journey kan volgen.
                Een maatschappelijke organisatie laat met een applicatie zien
                wat binnen haar muren mogelijk is. En ja, wat zou het mooi zijn
                als die tool breed ingezet kan worden! Een hogeschool zoekt een
                manier om haar beoordelingsmethodiek digitaal te
                standaardiseren.
              </p>
              <br />
              <p class="has-text-white">
                Allemaal projecten met een maatschappelijke touch. Met veel
                potentie, maar nog zonder harde businesscase. Te risicovol voor
                een commercieel IT-bedrijf, dat bovendien al gauw torenhoge
                tarieven rekent (“da’s veels te duur voor ons …!”).
              </p>
              <br />
              <p class="has-text-white">
                Grijp jij als opdrachtgever de kans aan om jouw project uit te
                laten voeren door het Dutch Software Lab, dan houd je de
                risico’s laag en de kosten beheersbaar. We zijn verbonden met de
                opleiding HBO-ICT van De Haagse Hogeschool. Je profiteert dus
                óók nog eens van de meest recente ICT-ontwikkelingen. En je
                profileert je als toekomstig werkgever bij de studenten die voor
                jou gaan engineeren.
              </p>
            </div>
            <div class="column is-5-desktop">
              <p
                id="opdrachtgevers-quote"
                class="is-size-2 mt-6 ml-6 is-italic has-text-weight-bold has-text-primary"
              >
                'Wij voeren IT-projecten uit met een maatschappelijke touch'
              </p>
              <br />
              <img
                id="opdrachtgevers-image"
                src="../assets/opdrachtgevers.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns is-desktop">
            <div class="column is-7-desktop">
              <p
                class="has-text-right has-text-weight-bold has-text-black is-size-5 m-3"
              >
                Meld je bij ons aan met jouw idee!
                <a href="mailto:info@dutchsoftwarelab.nl"
                  ><button class="button is-primary m-3">Aanmelden</button></a
                >
              </p>
            </div>
            <div class="column is-5-desktop"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
@media screen and (min-width: 1024px) {
  #opdrachtgevers-quote {
    margin-right: -25%;
  }
  #opdrachtgevers-image {
    max-width: unset;
    width: 120%;
    margin-bottom: -300px;
  }
  #opdrachtgevers {
    padding-bottom: 200px;
  }
}
</style>
