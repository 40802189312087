<script>
export default {
  name: "Docenten",
};
</script>

<template>
  <section id="docenten" class="section is-small pl-0 has-background-white">
    <div class="columns is-mobile">
      <div class="column is-11-mobile is-11-desktop">
        <div class="has-background-info">
          <div class="columns is-desktop">
            <div class="column is-7-desktop p-6">
              <h4>03</h4>
              <h2 class="has-text-white is-size-3 mt-4 mb-4">
                Aan docenten die verlangen naar de praktijk:
              </h2>
              <p class="has-text-white">
                Heb je als docent je la vol met geschreven software? Ben je bang
                dat die daar altijd blijft liggen? Níet als het aan het Dutch
                Software Lab ligt. Wij willen graag jouw software hebben om die
                verder te ontwikkelen. Goede ideeën mogen niet verloren gaan. En
                als je dan een deel van je tijd komt doorbrengen binnen ons lab,
                dan weet je ook weer hoe bedrijven denken, wat ze verwachten van
                jou en van de studenten die je aan de maatschappij aflevert. Dat
                is kennis die je direct kunt inzetten in je lessen.
              </p>
              <br />
              <p class="has-text-white">
                Terwijl jij je handen in het Dutch Software Lab vuilmaakt aan
                een echte IT-klus, heb je te maken met studenten die allemaal
                steengoed hun best doen. Je kunt jouw vindingrijkheid en
                enthousiasme voor software op hen overbrengen. Niet in een
                klaslokaal, maar lekker op de werkvloer. Op deze manier creëer
                je echt het gevoel dat je samen aan het werk bent om iets moois
                te maken. Iets dat gelukkig weer uit die la tevoorschijn is
                gekomen en in het Dutch Software Lab kans van slagen krijgt.
              </p>
            </div>
            <div class="column is-5-desktop">
              <p
                id="docenten-quote"
                class="is-size-2 mt-6 ml-6 is-italic has-text-weight-bold has-text-primary"
              >
                Bij ons kun je je handen vuil maken aan een echte IT-klus’
              </p>
              <br />
              <img id="docenten-image" src="../assets/docenten.jpeg" alt="" />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns is-desktop">
            <div class="column is-7-desktop">
              <p
                class="has-text-right has-text-weight-bold has-text-black is-size-5 m-3"
              >
                Lang genoeg gedacht?
                <a href="mailto:info@dutchsoftwarelab.nl"
                  ><button class="button is-primary m-3">
                    Meld je bij ons aan
                  </button>
                </a>
              </p>
            </div>
            <div class="column is-5-desktop"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
@media screen and (min-width: 1024px) {
  #docenten-quote {
    margin-right: -33%;
  }
  #docenten-image {
    max-width: unset;
    width: 120%;
    margin-bottom: -300px;
  }
  #docenten {
    padding-bottom: 200px;
  }
}
</style>
