<script>
export default {
  name: "Studenten",
};
</script>

<template>
  <section id="studenten" class="section is-small pr-0 has-background-white">
    <div class="columns is-mobile">
      <div
        class="column is-11-mobile is-11-desktop is-offset-1-mobile is-offset-1-desktop"
      >
        <div class="has-background-info">
          <div class="columns is-desktop">
            <div class="column is-5-desktop">
              <p
                id="studenten-quote"
                class="is-size-2 mt-6 is-italic has-text-weight-bold has-text-primary"
              >
                ‘Je krijgt bij ons veel feeling met wat je na de studie gaat
                doen’
              </p>
              <img
                id="studenten-image"
                class="mt-6"
                src="../assets/studenten.jpg"
                alt=""
              />
            </div>
            <div class="column is-7-desktop p-6">
              <h4>02</h4>
              <h2 class="has-text-white is-size-3 mt-4 mb-4">
                Aan studenten die een stap méér willen zetten:
              </h2>
              <br />
              <p class="has-text-white">
                Ook voor een student is het Dutch Software Lab hartstikke
                interessant. Bijvoorbeeld als je de differentiatie Software
                Engineering of Innovative Development volgt van de opleiding
                HBO-ICT. Maar niet alleen dan. Wij gaan helemaal voor een
                kruisbestuiving met andere opleidingen. In een team dat software
                ontwikkelt en releast, komen veel disciplines samen. Al die
                disciplines zoeken wij. We zijn specialisten in software, maar
                generalisten in de aanpak.
              </p>
              <br />
              <p class="has-text-white">
                Wat je in het Dutch Software Lab ontwikkelt, is direct
                toepasbaar. Je doet het voor een klant. Je krijgt dus veel
                feeling met wat je na je studie gaat doen. Vergeet even het
                curriculum van je opleiding. Daar staan we los van. Werken bij
                ons levert een hoop ervaring op. Bijvoorbeeld als je naast je
                studie een uitdagende bijbaan zoekt. Als je op zoek bent naar
                een stageadres met impact. Of als je afgestudeerd bent, alumnus,
                maar nog niet goed weet voor welke baan jij je bed uit wilt
                komen.
              </p>
              <br />
              <p class="has-text-white">
                Meld je aan bij het Dutch Software Lab. We geven je graag een
                kans en kijken voor welk project we je in kunnen zetten.
              </p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="columns is-dekstop">
            <div class="column is-5-desktop"></div>
            <div class="column is-7-desktop">
              <p
                class="has-text-left has-text-weight-bold has-text-black is-size-5 m-4"
              >
                Neem gauw contact met ons op!
                <a href="mailto:info@dutchsoftwarelab.nl"
                  ><button class="button is-primary is-size-5 m-3 mt-1">
                    Neem contact op
                  </button></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
@media screen and (min-width: 1024px) {
  #studenten-quote {
    margin-left: -25%;
  }
  #studenten-image {
    max-width: unset;
    width: 125%;
    margin-left: -25%;
    margin-bottom: -300px;
  }
  #studenten {
    padding-bottom: 200px;
  }
}
</style>
