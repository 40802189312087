<script>
export default {
  name: "Footer",
};
</script>
<template>
  <footer class="footer has-background-info p-0">
    <a href="#"
      ><button id="arrow-up" class="button is-primary mr-0">
        <i class="fas fa-arrow-up"></i></button
    ></a>
    <div class="columns p-6">
      <div class="column is-6">
        <img
          class="pb-4"
          src="../assets/footer-logo.png"
          width="112"
          alt="dutch software lab logo"
        />
        <h3 class="has-text-white is-size-6">
          Tijd om werk te maken van je ideeën
        </h3>
        <br />
        <a class="has-text-white" href="https://instagram.com/dutchsoftwarelab"
          >@dutchsoftwarelab</a
        >
      </div>
      <div class="column is-6">
        <p class="has-text-white">
          Het Dutch Software Lab vind je in de Dutch Innovation Factory. Een
          inspirerende plek waar nieuwe ideeën vorm krijgen. Ook jouw idee?
        </p>
        <br />
        <h3 class="has-text-white">
          Mail ons:
          <a href="mailto:info@dutchsoftwarelab.nl"
            ><em class="has-text-primary">info@dutchsoftwarelab.nl</em></a
          >
        </h3>
        <h3 class="has-text-white">
          Of kom gezellig langs:
          <em class="has-text-primary">Bleiswijkseweg 37E, Zoetermeer</em>
        </h3>
      </div>
    </div>
    <img id="hhs-logo" width="150" src="../assets/footer-hhs-logo.png" />
  </footer>
</template>
<style scoped>
#arrow-up {
  float: right;
}
#hhs-logo {
  float: right;
  display: block;
}
footer {
  overflow: hidden;
}
</style>
